@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://use.typekit.net/vaz3ink.css");

.page-width {
  max-width: 1920px;
  margin: auto;
}

.hero-title {
  font-size: clamp(2.5rem, 7.5vw, 3rem);
  @apply mb-8;
}

.section-grid {
  @apply grid;
  @apply grid-cols-1;
}
@media screen and (min-width: 1024px) {
  .section-grid {
    @apply grid-cols-12;
    @apply gap-8;
  }

}

/* Terminal Emulator CSS */

.terminal_emulator {
  @apply absolute;
  @apply overflow-hidden;
  @apply bottom-0;
  @apply w-full;
  @apply min-h-full;
  @apply text-base;
}
@media screen and (min-width: 768px) {
  .terminal_emulator {
    @apply text-xl;
  }
}

#screen .terminal_emulator__field,
#screen .terminal_emulator__command,
#screen span {
  @apply font-normal;
  @apply text-white;
  @apply font-mono;
}

#screen .terminal_emulator__command {
  @apply block;
}


.terminal_emulator__field,
.terminal_emulator__command {
  @apply relative;
  margin: 0 0 9px 0;
}

.terminal_emulator__field::before,
.terminal_emulator__field::after,
.terminal_emulator__command::before,
.terminal_emulator__command::after {
  @apply absolute;
}

#screen .terminal_emulator__field::before,
#screen .terminal_emulator__command::before {
  @apply left-0;
  @apply top-0;
  content:">";
}
#screen .terminal_emulator__field,
#screen .terminal_emulator__command {
  padding: 0 1em;
}

.terminal_emulator__response,
.terminal_emulator__command b{
  padding-bottom: 9px;
}

.terminal_emulator__field {
  @apply inline-block;
  min-width: 0.5rem;
  min-height: 1rem;
  box-sizing: border-box;
}

.terminal_emulator__field::after {
  @apply right-0;
  bottom: 0.25em;
  content:"";
  width: 0.5rem;
  @apply h-4;
  @apply bg-white;
  animation: caretBlink 1s infinite;
}


#typingScreen .terminal_emulator__field::after {
  @apply -right-2;
  bottom: 0.1em;
  content: "";
  width: 0.2rem;
  @apply h-6;
  @apply bg-black;
  animation: caretBlink 1s infinite;
}
@media screen and (min-width: 1024px) {
  #typingScreen .terminal_emulator__field::after {
    width: 0.2rem;
    @apply h-8;
  }
}

.terminal_emulator__field.waiting {
  @apply pl-0;
  @apply pr-0;
}
.terminal_emulator__field.waiting::before {
  @apply hidden;
}


@keyframes caretBlink {
  0% {
    opacity: 0;
  }
  
  50% {
    opacity: 0;
  }
  
  51% {
    opacity: 1;
  }
  
  100% {
    opacity: 1;
  }
}

/* Card Animation */
@keyframes rotate-12 {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(9deg);
  }
}

@keyframes rotate-25-1 {
  0% {
    transform: rotate(0);
    left: 0;
  }
  100% {
    transform: rotate(24deg);
    left: 1rem;
  }
}
@keyframes rotate-25-2 {
  0% {
    transform: rotate(0);
    left: 0;
  }
  100% {
    transform: rotate(24deg);
    left: 2rem;
  }
}

.card {
  @apply top-0 left-0 bg-white border border-[#D9D9D9] rounded-xl p-6 w-[243px];
}
.card:nth-child(2), .card:nth-child(3) {
  @apply absolute;
}

.animate-section .card:nth-child(3) {
  animation-name: rotate-25-1;
  animation-duration: 0.5s;
  animation-delay: 1.2s;
  -webkit-animation-fill-mode: forwards;
}
.animate-section .card:nth-child(2) {
  animation-name: rotate-12;
  animation-duration: 0.5s;
  animation-delay: 1s;
  -webkit-animation-fill-mode: forwards;
}
.animate-section .card:nth-child(3) {
  @apply absolute;
  animation-name: rotate-25-1;
  animation-duration: 0.5s;
  animation-delay: 1.2s;
  -webkit-animation-fill-mode: forwards;
}
@media screen and (min-width: 768px) {
  .card {
    @apply top-0 left-0 bg-white border border-[#D9D9D9] rounded-xl p-12 w-[437px];
  }
  .animate-section .card:nth-child(3) {
    animation-name: rotate-25-2;
  }
}

h1, h2, h3, h4, h4, p, a, ul, li, span, strong{
  @apply text-offblack;
  @apply font-indivisible;
}

#pricing ul {
  list-style: url(../public/assets/check.svg);
  @apply list-inside;
}

.question {
  @apply overflow-y-hidden;
  box-shadow: 0px 1px 3px #3F3F4426;
}

.answer[open] {
  @apply block;
}
.answer {
  @apply hidden;
  @apply pt-4
}